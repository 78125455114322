import { MobileMenuStaticType } from "./types"
import { ROUTES } from "@/utils/constants"

export const getTapBarMenu: (isAuth: boolean) => MobileMenuStaticType[] = (
  isAuth = false,
) => {
  const menu: MobileMenuStaticType[] = [
    {
      title: "Главная",
      path: ROUTES.home,
      icon: "Home",
      isAuthGuard: false,
      pageAlias: "home",
    },
    {
      title: "Каталог",
      path: `${ROUTES.catalog}`,
      icon: "Catalog",
      isAuthGuard: false,
      pageAlias: "catalog",
    },
    {
      title: "Корзина",
      path: ROUTES.cart,
      icon: "ShoppingCart",
      pageAlias: "cart",
    },
    {
      title: "Заказы",
      path: `${ROUTES.account}${ROUTES.historyOrders}`,
      icon: "Orders",
      isAuthGuard: true,
      pageAlias: "historyOrders",
    },
    {
      title: "Профиль",
      path: ROUTES.account,
      icon: "User",
      isAuthGuard: true,
      pageAlias: "account",
    }
  ]

  return menu
}
