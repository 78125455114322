import { FC, memo } from "react";
import { useRouter } from "next/router";
import { cx } from "@linaria/core";
import { cssIsActive, cssItem, MenuBar, StyledFooterBar } from "./Styled";
import { MenuLinkIconI } from "./types";
import { PushCounter } from "@/components/PushCounter";
import { useAuth } from "@/hooks/auth";
import { useCart } from "@/hooks/cart/cart";
import { useAppDispatch } from "@/hooks/redux";
import { modalsAlias, setIsShowModal } from "@/store/reducers/commonSlice";
import { Container } from "@/styles/utils/StyledGrid";
import { cssAuthGuardInvalid } from "@/styles/utils/Utils";
import { Button } from "@/ui/Button/Button";
export const TapBar: FC<MenuLinkIconI> = memo(({
  data
}) => {
  const {
    route
  } = useRouter();
  const {
    isAuth
  } = useAuth();
  const {
    cartCount: qtyCart
  } = useCart();
  const matchingCounter: {
    cart: number;
  } = {
    cart: qtyCart || 0
  };
  const dispatch = useAppDispatch();
  const isActive = (path: string): boolean => route === path;
  const getIsAuthResolve = (isAuthGuard: boolean) => isAuthGuard ? isAuth : true;
  return <StyledFooterBar>
      <Container>
        <MenuBar>
          {data.map(({
          title,
          icon,
          path,
          isAuthGuard = false,
          pageAlias
        }) => {
          const isAuthResolve = getIsAuthResolve(isAuthGuard);
          const availableAlias: number | undefined = matchingCounter[pageAlias];
          return <Button key={title} isClean className={cx(cssItem, isActive(path) && cssIsActive, !isAuthResolve && cssAuthGuardInvalid)} icon={icon} as={isAuthResolve ? "a" : undefined} href={isAuthResolve ? path : undefined} onClick={e => {
            if (isAuthResolve) {
              return;
            }
            e.preventDefault();
            dispatch(setIsShowModal({
              isShow: true,
              alias: modalsAlias.implementAuth
            }));
          }}>
                <>
                  {!!availableAlias && <PushCounter qty={availableAlias} />}
                  {title}
                </>
              </Button>;
        })}
        </MenuBar>
      </Container>
    </StyledFooterBar>;
});
TapBar.displayName = "TapBar";