import { FC } from "react"
import { getTapBarMenu } from "./constants"
import { TapBar } from "./TapBar"
import { useAppSelector } from "@/hooks/redux"
import { useWindowSize } from "@/hooks/windowSize"
import { getBreakpointVal } from "@/styles/utils/Utils"
import { breakpoints } from "@/styles/utils/vars"

const FooterBar: FC = () => {
  const isAuth = useAppSelector((state) => state.profile.isAuth)
  const { width } = useWindowSize()
  const isLessLg =
    width !== undefined && width <= getBreakpointVal(breakpoints.lg)

  if (!isLessLg) {
    return null
  }

  return <TapBar data={getTapBarMenu(isAuth)} />
}

export { FooterBar }
